import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ChartStudentMarksDateTrends({
  data,
  percentComplete,
  finished,
}) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  //MARK: CHART OPS
  const { chartOptions } = useMemo(() => {
    const gradePoints = [
      { grade: "A", points: 4.0 },
      { grade: "A-", points: 3.7 },
      { grade: "B+", points: 3.3 },
      { grade: "B", points: 3.0 },
      { grade: "B-", points: 2.7 },
      { grade: "C+", points: 2.3 },
      { grade: "C", points: 2.0 },
      { grade: "C-", points: 1.7 },
      { grade: "D+", points: 1.3 },
      { grade: "D", points: 1.0 },
      { grade: "F", points: 0 },
      { grade: "E", points: 0 },
    ];

    // Get grade points
    const getGradePoints = (mark) => {
      const gradePoint = gradePoints?.find((gp) => gp.grade === mark);
      return gradePoint ? gradePoint.points : 0;
    };

    // Group the data by weekDate and calculate GPA
    const dateGroupedData = {};

    data?.forEach((item) => {
      const date = item.weekDate; // Assuming the date field is named 'weekDate'
      const mark = item.mark;

      if (!dateGroupedData[date]) {
        dateGroupedData[date] = { totalPoints: 0, count: 0 };
      }
      dateGroupedData[date].totalPoints += getGradePoints(mark);
      dateGroupedData[date].count += 1;
    });

    // Calculate average GPA for each date
    const dates = Object.keys(dateGroupedData).sort();
    const gpaData = dates.map((date) => {
      const { totalPoints, count } = dateGroupedData[date];
      return (totalPoints / count).toFixed(2);
    });

    // Generate chart options for trend line
    const chartOptions = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
        },
      },
      xAxis: {
        type: "category",
        data: dates,
        axisLabel: {
          rotate: 45, // Rotate labels by 45 degrees
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: 4,
        interval: 0.5,
      },
      series: [
        {
          name: "GPA",
          type: "line",
          data: gpaData?.map(Number), // Ensure data is in number format
          label: {
            show: true,
            formatter: (params) => (params.value === 0 ? "" : params.value),
          },
          emphasis: {
            focus: "series",
          },
          color: theme.palette.chart && theme.palette.chart[0],
        },
      ],
    };

    return { chartOptions };
  }, [data, theme]);

  //MARK: RENDER
  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography variant="h6" color={theme.palette.text.secondary}>
        Grade Trends
      </Typography>
      {percentComplete < 100 && !finished ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 6,
            minHeight: 200,
          }}
        >
          {percentComplete === 0 ? (
            <CircularProgress />
          ) : (
            <CircularProgress variant="determinate" value={percentComplete} />
          )}
          <Typography variant="caption" sx={{ m: 1 }}>
            {`${percentComplete}%`}
          </Typography>
        </Box>
      ) : (
        <EChartsReact option={chartOptions} />
      )}
    </Paper>
  );
}
