import { useMemo } from "react";
import EChartsReact from "echarts-for-react";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function ChartStudentMarksAllSubjects({
  data,
  percentComplete,
  finished,
}) {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  //MARK: CHART OPS
  const { chartOptions } = useMemo(() => {
    const validMarks = ["A", "B", "C", "D", "E"];

    // Normalize marks
    const normalizeMark = (mark) => {
      if (mark?.startsWith("A")) return "A";
      if (mark?.startsWith("B")) return "B";
      if (mark?.startsWith("C")) return "C";
      if (mark?.startsWith("D")) return "D";
      if (mark === "F") return "E";
      return mark;
    };

    // Group the data for all subjects
    const allSubjectsData = {};

    data?.forEach((item) => {
      const mark = normalizeMark(item.mark);

      if (!allSubjectsData[mark]) {
        allSubjectsData[mark] = 0;
      }
      allSubjectsData[mark] += 1;
    });

    // Generate chart options for "All Subjects"
    const subjects = ["All Subjects"];
    const series = validMarks.map((mark, i) => ({
      name: mark,
      type: "bar",
      stack: "total",
      label: {
        show: true,
        formatter: (params) => (params.value === 0 ? "" : params.value),
      },
      emphasis: {
        focus: "series",
      },
      data: [allSubjectsData[mark] || 0],
      color: theme.palette.chart && theme.palette.chart[i],
    }));

    const chartOptions = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: subjects,
      },
      yAxis: {
        type: "value",
      },
      legend: {
        orient: "vertical",
        bottom: "bottom",
        textStyle: {
          color: theme.palette.text.secondary,
        },
      },
      series,
    };

    return { chartOptions };
  }, [data, theme]);

  //MARK: RENDER
  return (
    <Paper sx={commonStyles.paperPadding}>
      <Typography variant="h6" color={theme.palette.text.secondary}>
        All Subjects
      </Typography>
      {percentComplete < 100 && !finished ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 6,
            minHeight: 200,
          }}
        >
          {percentComplete === 0 ? (
            <CircularProgress />
          ) : (
            <CircularProgress variant="determinate" value={percentComplete} />
          )}
          <Typography variant="caption" sx={{ m: 1 }}>
            {`${percentComplete}%`}
          </Typography>
        </Box>
      ) : (
        <EChartsReact option={chartOptions} />
      )}
    </Paper>
  );
}
