import { useState, useMemo, useEffect } from "react";
import {
  Box,
  CircularProgress,
  LinearProgress,
  Link,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  TextField,
  Grid2 as Grid,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import {
  useGetMarkData,
  useGetMarkDataTotalRowCount,
  useGetMarkDataByDateRange,
  useGetMarkDateRange,
} from "../../services/hooks/partnerReportsHook";
import ParticipantDetails from "./ParticipantDetails";
import ChartStudentMarks from "./ChartStudentMarks";
import ChartStudentMarksAllSubjects from "./ChartStudentMarksAllSubjects";
import { useOutletContext } from "react-router";
import ReportsToolbar from "./ReportsToolbar";
import ChartStudentMarksDateTrends from "./ChartStudentMarksDateTrends";

export default function ReportsMarks() {
  const [slicers] = useOutletContext();
  const apiRef = useGridApiRef();

  const allSlicers = slicers?.data?.data;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [inspectParticipant, setInspectParticipant] = useState(null);
  const [percentComplete, setPercentComplete] = useState(0);
  const [finished, setFinished] = useState(false);
  const [showName, setShowName] = useState(false);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [selectedRaces, setSelectedRaces] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [filteredRowIds, setFilteredRowIds] = useState([]);
  const [showCurrent, setShowCurrent] = useState(true);
  const getMostRecentFriday = () => {
    const today = dayjs();
    const dayOfWeek = today.day(); // 0 (Sunday) to 6 (Saturday)
    const daysSinceFriday = ((dayOfWeek + 1) % 7) + 1; // days since last Friday
    return today.subtract(daysSinceFriday, "day").format("YYYY-MM-DD");
  };

  const getFridayBeforeMostRecentFriday = () => {
    const mostRecentFriday = dayjs(getMostRecentFriday());
    return mostRecentFriday.subtract(7, "day").format("YYYY-MM-DD");
  };
  const [startDate, setStartDate] = useState(getFridayBeforeMostRecentFriday());
  const [endDate, setEndDate] = useState(getMostRecentFriday());
  const [showHistorical, setShowHistorical] = useState(false);
  const [showDates, setShowDates] = useState(false);

  //MARK: COLUMNS
  const [columns, setColumns] = useState([
    { field: "studentId", headerName: "ID", width: 100 },
    {
      field: "school",
      headerName: "School",
      width: 120,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "grade",
      headerName: "Grade",
      headerAlign: "center",
      width: 60,
      align: "center",
    },
    {
      field: "gender",
      headerName: "Gender",
      headerAlign: "center",
      width: 70,
      align: "center",
    },
    {
      field: "race",
      headerName: "Race",
      width: 120,
      minWidth: 90,
      flex: showName ? 0 : 1,
    },
    {
      field: "schoolYear",
      headerName: "School Year",
      headerAlign: "center",
      width: 88,
      align: "center",
    },
    {
      field: "markingPeriod",
      headerName: "Marking Period",
      headerAlign: "center",
      width: 112,
      align: "center",
    },
    {
      field: "period",
      headerName: "Period",
      headerAlign: "center",
      align: "center",
      width: 60,
    },
    {
      field: "teacherName",
      headerName: "Teacher",
      width: 180,
      minWidth: 160,
      flex: 1,
    },
    {
      field: "courseSubject",
      headerName: "Subject",
      width: 160,
    },
    {
      field: "mark",
      headerName: "Mark",
      headerAlign: "center",
      align: "center",
      width: 60,
    },
  ]);

  useEffect(() => {
    if (showDates) {
      setColumns((prevColumns) => [
        ...prevColumns,
        {
          field: "weekDate",
          headerName: "As Of",
          headerAlign: "center",
          width: 120,
          align: "center",
        },
      ]);
    } else {
      setColumns((prevColumns) =>
        prevColumns.filter((col) => col.field !== "weekDate")
      );
    }
  }, [showDates]);

  //#region DATA
  const queryRowCount = useGetMarkDataTotalRowCount(slicers, showCurrent);
  const dataRowCount = useMemo(() => {
    return queryRowCount?.status === "success" ? queryRowCount?.data?.data : 0;
  }, [queryRowCount]);

  const queryMarkData = useGetMarkData(slicers, dataRowCount, showCurrent);
  const queryMarkDataByDateRange = useGetMarkDataByDateRange(
    slicers,
    dataRowCount,
    startDate,
    endDate,
    showDates
  );

  const queryMarkDateRange = useGetMarkDateRange(
    slicers,
    dataRowCount,
    showDates
  );

  const uniqueDates = useMemo(() => {
    if (queryMarkDateRange?.queries?.length > 0) {
      return queryMarkDateRange?.queries
        ?.map((q) => {
          return q?.isSuccess ? q?.data?.data : [];
        })
        .flat()
        .map((q) => {
          const dateStr = Object.keys(q)
            .filter((key) => !isNaN(key))
            .map((key) => q[key])
            .join("");
          return dayjs(dateStr).format("MM-DD-YYYY");
        });
    } else {
      return [];
    }
  }, [queryMarkDateRange]);

  const query = showCurrent ? queryMarkData : queryMarkDataByDateRange;

  const data = useMemo(() => {
    if (query?.queries?.length > 0) {
      return query?.queries
        ?.map((q) => {
          return q?.isSuccess ? q?.data?.data : [];
        })
        .flat()
        .map((q, i) => {
          return { ...q, id: i };
        });
    } else {
      return [];
    }
  }, [query]);

  //Get date for date picker

  //#endregion

  //#region EFFECTS
  //set up loading states
  useEffect(() => {
    if (query?.percentComplete) {
      setPercentComplete(query.percentComplete);
    }
    if (!queryRowCount.isLoading && dataRowCount === 0) {
      setFinished(true);
    } else {
      setFinished(query?.finished && query?.finished);
    }
  }, [query, queryRowCount, dataRowCount]);

  //show students names or not
  useEffect(() => {
    if (data) {
      setShowName(
        data?.some(
          (data) => data?.studentName !== "" && data?.studentName !== null
        )
      );
    }
  }, [data]);
  useEffect(() => {
    if (showName && !columns.some((col) => col.field === "studentName")) {
      const editColumns = [...columns];
      editColumns.splice(1, 0, {
        field: "studentName",
        headerName: "Name",
        width: 180,
        minWidth: 100,
        flex: 1,
        renderCell: (val) => {
          return (
            <Link
              onClick={() => {
                setInspectParticipant(val.row.participantId);
              }}
              sx={{ cursor: "pointer" }}
            >
              <strong>{val.formattedValue}</strong>
            </Link>
          );
        },
      });
      setColumns(editColumns);
    }
  }, [columns, showName]);
  //More useEffects in Toolbar section below
  //#endregion

  //#region TOOLBAR
  const filterControls = [
    {
      id: "school",
      field: "school",
      label: "School/Location",
      options: useMemo(
        () => [...new Set(allSlicers?.locations?.map((l) => l?.name))] ?? [], //slicer returns array of objects, using Set and map() to get just the unique location names
        [allSlicers]
      ),
      selectedValues: selectedSchools,
      setSelectedValues: setSelectedSchools,
      slicerFilterFn: (s) => selectedSchools?.includes(s),
      modelFilterFn: (item) =>
        item.field.toLowerCase() === "school" && item.operator === "isAnyOf",
    },
    {
      id: "grade",
      field: "grade",
      label: "Grade",
      options: useMemo(
        () => allSlicers?.grades?.map((g) => g?.grade) ?? [], //slicer returns array of objects, using map() to get just the grade values
        [allSlicers]
      ),
      selectedValues: selectedGrades,
      setSelectedValues: setSelectedGrades,
      slicerFilterFn: (s) => selectedGrades?.includes(s),
      modelFilterFn: (item) =>
        item.field.toLowerCase() === "grade" && item.operator === "isAnyOf",
    },
    {
      id: "gender",
      field: "gender",
      label: "Gender",
      options: useMemo(() => allSlicers?.genders ?? [], [allSlicers]),
      selectedValues: selectedGenders,
      setSelectedValues: setSelectedGenders,
      slicerFilterFn: (s) => selectedGenders?.includes(s),
      modelFilterFn: (item) =>
        item.field.toLowerCase() === "gender" && item.operator === "isAnyOf",
    },
    {
      id: "race",
      field: "race",
      label: "Race",
      options: useMemo(() => allSlicers?.races ?? [], [allSlicers]),
      selectedValues: selectedRaces,
      setSelectedValues: setSelectedRaces,
      slicerFilterFn: (s) => selectedRaces?.includes(s),
      modelFilterFn: (item) =>
        item.field.toLowerCase() === "race" && item.operator === "isAnyOf",
    },
    //If adding a new filter control object, be sure to add:
    //    - its state variables above [selectedX, setSelectedX] = useState([]);
    //    - selectedX as a dependency to the appropriate useEffect below
  ];

  const toolbarProps = {
    filterControls,
    filterModel,
    onFilterModelChange: handleDataGridFilterChange,
    isLoading: slicers.isLoading,
  };

  // Single useEffect to handle all filter changes
  useEffect(() => {
    const fieldsToUpdate = filterControls.map((control) =>
      control.field.toLowerCase()
    );

    const existingFilteredItems = filterModel?.items.filter(
      (item) => !fieldsToUpdate.includes(item.field.toLowerCase())
    );

    const newFilters = filterControls
      .filter((control) => control.selectedValues?.length > 0)
      .map((control) => ({
        field: control.field,
        operator: "isAnyOf",
        id: `${control.id}-filter`,
        value: control.selectedValues,
      }));

    const newFilterModel = {
      ...filterModel,
      items: [...existingFilteredItems, ...newFilters],
    };

    setFilterModel(newFilterModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRaces, selectedGenders, selectedGrades, selectedSchools]);

  //set up filtering shared by data grid and chart
  useEffect(() => {
    const fr = apiRef.current.state.filter.filteredRowsLookup;
    setFilteredRowIds(fr);
  }, [apiRef, filterModel]);
  const [chartData, setChartData] = useState(data);
  useEffect(() => {
    apiRef.current.setRows(data);
  }, [apiRef, data]);
  useEffect(() => {
    const filteredData = data?.filter((row) => filteredRowIds?.[row.id]);
    setChartData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRowIds]);
  //#endregion

  //#region STYLES
  const gridStyles = {
    border: "none",
    minHeight: "640px", //needed so dropdowns don't get cut off when the grid is short
    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle":
      // allowing for wrapping of long column header titles
      {
        lineHeight: "1rem",
        whiteSpace: "normal",
      },
    "& .MuiDataGrid-columnHeader:last-of-type": {
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
    },
  };
  //#endregion

  //MARK: RENDER
  return (
    <>
      {/* Participant details dialog */}
      {inspectParticipant && (
        <ParticipantDetails
          participantId={inspectParticipant}
          onClose={() => {
            setInspectParticipant(null);
          }}
        />
      )}
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography variant="h4" component="h3">
          Student Marks <small style={{ fontWeight: 300 }}>(Grades)</small>
        </Typography>
        <ToggleButtonGroup
          value={
            showCurrent ? "current" : showHistorical ? "historical" : "dates"
          }
          exclusive
          onChange={(event, value) => handleToggle(event, value)}
          aria-label="Current, Historical, or Dates"
          color="primary"
          size="small"
          sx={{ ml: 4 }}
        >
          <ToggleButton value="current" disabled={showCurrent}>
            Current
          </ToggleButton>
          <ToggleButton value="historical" disabled={showHistorical}>
            Historical
          </ToggleButton>
          <ToggleButton value="dates" disabled={showDates}>
            Dates
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {showDates && (
        <>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, pt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ pr: 2 }}
                label="Start Date"
                value={startDate ? dayjs(startDate) : null}
                shouldDisableDate={(date) => {
                  const formattedDate = dayjs(date).format("MM-DD-YYYY");
                  const isAfterEndDate =
                    endDate && dayjs(date).isAfter(dayjs(endDate));
                  return !uniqueDates.includes(formattedDate) || isAfterEndDate;
                }}
                onChange={(newValue) =>
                  setStartDate(dayjs(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField {...params} sx={{ mr: 2 }} />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate ? dayjs(endDate) : null}
                onChange={(newValue) =>
                  setEndDate(dayjs(newValue).format("YYYY-MM-DD"))
                }
                shouldDisableDate={(date) => {
                  const formattedDate = dayjs(date).format("MM-DD-YYYY");
                  const isBeforeStartDate =
                    startDate && dayjs(date).isBefore(dayjs(startDate));
                  return (
                    !uniqueDates.includes(formattedDate) || isBeforeStartDate
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Typography variant="caption" sx={{ ml: 2, pb: 2 }}>
              To provide you data in between report cards, we take snapshots of
              student marks in teacher gradebooks every Friday. <br></br>Note
              that these began 10/11/24 and that late assignments, delays in
              grading, etc. may affect these running averages.
            </Typography>
          </Box>
        </>
      )}
      <Box sx={{ mb: 2, maxWidth: "calc(100% - 0.5rem)" }}>
        <Grid container spacing={2}>
          <Grid item size={{ xs: 8 }}>
            <ChartStudentMarks
              data={chartData?.length > 0 ? chartData : data}
              percentComplete={percentComplete}
              finished={finished}
            />
          </Grid>
          <Grid item size={{ xs: 4 }}>
            <ChartStudentMarksAllSubjects
              data={chartData?.length > 0 ? chartData : data}
              percentComplete={percentComplete}
              finished={finished}
            />
          </Grid>
          <Grid item size={{ xs: 12 }}>
            {!!showDates && (
              <ChartStudentMarksDateTrends
                data={chartData?.length > 0 ? chartData : data}
                percentComplete={percentComplete}
                finished={finished}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Paper sx={{ maxWidth: "calc(100% - 0.5rem)" }}>
        <DataGridPremium
          apiRef={apiRef}
          rows={data}
          columns={columns}
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            sorting: {
              sortModel: [{ field: "studentName", sort: "asc" }],
            },
            filterModel: filterModel,
          }}
          disableRowSelectionOnClick={true}
          autoHeight
          sx={gridStyles}
          columnHeaderHeight={64}
          filterModel={filterModel}
          onFilterModelChange={(model) => handleDataGridFilterChange(model)}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          loading={!finished}
          slots={{
            toolbar: ReportsToolbar,
            loadingOverlay: () => {
              return (
                <>
                  {percentComplete === 0 ? (
                    <LinearProgress />
                  ) : (
                    <LinearProgress
                      variant="determinate"
                      value={percentComplete}
                    />
                  )}
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        m: 6,
                      }}
                    >
                      {percentComplete === 0 ? (
                        <CircularProgress />
                      ) : (
                        <CircularProgress
                          variant="determinate"
                          value={percentComplete}
                        />
                      )}
                      <Typography variant="caption" sx={{ m: 1 }}>
                        {`${percentComplete}%`}
                      </Typography>
                    </Box>
                  </>
                </>
              );
            },
            noRowsOverlay: () => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    color="textSecondary"
                    sx={{ p: 2 }}
                  >
                    {query?.errors ? (
                      <>{"We encountered some errors. "}</>
                    ) : (
                      "No data"
                    )}
                  </Typography>
                </Box>
              );
            },
          }}
          slotProps={{
            toolbar: toolbarProps,
          }}
        />
      </Paper>
    </>
  );

  // MARK: FUNCTIONS
  function handleDataGridFilterChange(model) {
    setFilterModel(model);

    filterControls.forEach(({ modelFilterFn, setSelectedValues }) => {
      const filter = model?.items?.find(modelFilterFn);
      if (filter) {
        setSelectedValues(filter?.value);
      } else {
        setSelectedValues([]);
      }
    });
  }

  function handleToggle(event, value) {
    setShowCurrent(value === "current");
    setShowHistorical(value === "historical");
    setShowDates(value === "dates");
  }
}
